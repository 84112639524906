import React from "react";
import { Heading } from "../../components";
import { richText } from "../../util/richText";

import css from "./ListingPage.module.css";
import Markdown from "markdown-to-jsx";

const MIN_LENGTH_FOR_LONG_WORDS = 20;

const getClickableLink = link => {
  return link.startsWith("http://") || link.startsWith("https://") ? link : `http://${link}`;
};

const SectionTextMaybe = props => {
  const { text, heading, showAsIngress = false, showAsMarkdown = false } = props;
  const textClass = showAsIngress ? css.ingress : css.text;
  const content = richText(text, {
    longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
    longWordClass: css.longWord,
    breakChars: "/"
  });

  if (showAsMarkdown) {
    return text ? (
      <div className={css.sectionText}>
        {heading ? (
          <Heading as="h2" rootClassName={css.sectionHeading}>
            {heading}
          </Heading>
        ) : null}
        <Markdown
          options={{
            overrides: {
              a: {
                component: props => {
                  const { href } = props;

                  return (
                    <a
                      {...props}
                      href={getClickableLink(href)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {props.children}
                    </a>
                  );
                }
              }
            }
          }}
        >
          {text}
        </Markdown>
      </div>
    ) : null;
  }

  return text ? (
    <div className={css.sectionText}>
      {heading ? (
        <Heading as="h2" rootClassName={css.sectionHeading}>
          {heading}
        </Heading>
      ) : null}
      <p className={textClass}>{content}</p>
    </div>
  ) : null;
};

export default SectionTextMaybe;
