import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Heading } from '../../components';

import css from './ListingPage.module.css';
import { brandOptions, listingCategories } from '../../config/configListing';

const findOptionLabel = (options, key) => {
  if (!options || !key) {
    return null;
  }
  const option = options.find(item => item.key === key);
  if (option) {
    return option.label;
  }
  return null;
};

const DetailRow = ({ key, label, value }) => {
  return (
    <li key={key} className={css.detailsRow}>
      <span className={css.detailLabel}>{label}</span>
      <span>{value}</span>
    </li>
  );
};

const SectionDetailsMaybe = props => {
  const { publicData, metadata = {}, listingConfig, intl } = props;
  const { listingFields } = listingConfig || {};

  if (!publicData || !listingConfig) {
    return null;
  }

  const pickListingFields = (filteredConfigs, config) => {
    const { key, schemaType, enumOptions, showConfig = {} } = config;
    const { isDetail, label } = showConfig;
    const publicDataValue = publicData[key];
    const metadataValue = metadata[key];
    const value = publicDataValue || metadataValue;

    if (isDetail && typeof value !== 'undefined') {
      const findSelectedOption = enumValue => enumOptions?.find(o => enumValue === `${o.option}`);
      const getBooleanMessage = value =>
        value
          ? intl.formatMessage({ id: 'SearchPage.detailYes' })
          : intl.formatMessage({ id: 'SearchPage.detailNo' });
      const optionConfig = findSelectedOption(value);

      return schemaType === 'enum'
        ? filteredConfigs.concat({ key, value: optionConfig?.label, label })
        : schemaType === 'boolean'
        ? filteredConfigs.concat({ key, value: getBooleanMessage(value), label })
        : schemaType === 'long'
        ? filteredConfigs.concat({ key, value, label })
        : filteredConfigs;
    }
    return filteredConfigs;
  };

  const { brand, category, subCategory } = publicData || {};

  const existingListingFields = listingFields.reduce(pickListingFields, []);
  const subCategoriesOptions = listingCategories.find(item => item.key === category)?.subcategories;

  const brandLabel = findOptionLabel(brandOptions.options, brand);
  const categoryLabel = findOptionLabel(listingCategories, category);
  const subCategoryLabel = findOptionLabel(subCategoriesOptions, subCategory);

  const updatedOptions = [
    ...(categoryLabel
      ? [
          {
            key: category,
            label: intl.formatMessage({ id: 'SectionDetailsMaybe.categoryTitle' }),
            value: categoryLabel,
          },
        ]
      : []),
    ...(subCategoryLabel
      ? [
          {
            key: subCategory,
            label: intl.formatMessage({ id: 'SectionDetailsMaybe.subCategoryTitle' }),
            value: subCategoryLabel,
          },
        ]
      : []),
  ];
  const brandOption = brandLabel
    ? [
        {
          key: brand,
          label: intl.formatMessage({ id: 'SectionDetailsMaybe.brandTitle' }),
          value: brandLabel,
        },
      ]
    : [];

  const updatedListingFields = updatedOptions.concat(existingListingFields).concat(brandOption);

  return updatedListingFields.length > 0 ? (
    <div className={css.sectionDetails}>
      <Heading as="h2" rootClassName={css.sectionHeading}>
        <FormattedMessage id="ListingPage.detailsTitle" />
      </Heading>
      <ul className={css.details}>
        {updatedListingFields.map(detail => (
          <DetailRow key={detail.key} value={detail.value} label={detail.label} />
        ))}
      </ul>
    </div>
  ) : null;
};

export default SectionDetailsMaybe;
